import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import InputField from "../component/InputField";
import SidebarPay from "../component/Sidebar";
import { fetchData } from "../globalAPI/globalAPI";
import { useMutation, useQuery } from "react-query";
import moment from "moment";
import axios from "axios";

import DatePicker from "react-datepicker";
export default function ViewEmployeePage() {
  const navigate = useNavigate();
  const user = sessionStorage.getItem("user");
  const userId = sessionStorage.getItem("userId");
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    register,
    watch,
    reset,
  } = useForm({
    defaultValues: {},
  });
  useEffect(() => {
    if(user =='employee'){

      setValue("employeeId",userId );
    }
  }, [user]);
  // API
  const[isData,setIsData]=useState(null)
  const { mutate, isLoading, isError, isSuccess,data } = useMutation(
    (postData) =>
      axios.post(
        `${process.env.REACT_APP_HOST}/employees/payroll`,
        postData
      ),
    {
      onSuccess: (data) => {
        setIsData(data?.data)
        console.log("SuccessFully", data?.data);
       
        reset();
        // alert("User Added SuccessFull");
      },
      onError: (error) => {
        alert(error)
      },
    }
  );
  const user_role = sessionStorage.getItem("user");
  useEffect(()=>{
    if(data?.data.length ==0&&user_role!=='employee'){
      reset()
      setValue("employeeId",'')
      alert("Payslip not Found")
    }else if(data?.data.length ==0){
      alert("Payslip not Found")
    }

  },[data,user_role])
  console.log("VIEWWWWWW<<<<>>data",data);
  // API End
  const companyName = sessionStorage.getItem("companyname");
  const onSubmit = (value) => {
    let Value = {
      value3: moment(value?.correntdate).format("YYYY"),
      value2: moment(value?.correntdate).format("MMMM"),
      value1: value?.employeeId,
      value4:companyName === "SIFM"?"Sampoornam Integrated Facility Management Private Limited" :companyName === "IPF"?"Intelligent Protection Force Pvt.Ltd":companyName === "IPF FMS"?"IPF Facility Management(India) Pvt.Ltd":null
    };
  
    console.log("data_paySlip", );
    if(companyName === "SIFM"){
      mutate(Value)
    }else if(companyName === "IPF"){
      mutate(Value)
    }else if(companyName === "IPF FMS"){
      mutate(Value)

    }
    // setShouldFetch(true);
    // mutate(Value)
   
  };
  // const { isLoading, isError, data, isRefetching } = useQuery(
  //   ["fetchData", isValue],
  //   () => fetchData({ params:isValue }),
  //   {
  //     enabled: shouldFetch,
  //     // Automatically reset the trigger on query completion or error
  //     onSettled: () => setShouldFetch(false),
  //     onSuccess: (data) => {
  //       console.log("DATA_LOGINSUCESS", data?.role);
  //       navigate("/paySlip",{state:data});
  //       // window.location.reload();
  //     },
  //   }
  // );
 // API Start
 
  
  console.log(!user, "!user");
  useEffect(()=> {
    if(isData!==''&&isData!==null&&isData.length!==0 ){
      navigate("/paySlip",{state:isData});
    }
  },[isData])
  const minDate = new Date(2024, 3, 1);
  return (
    <div>
      <div className="container-fluid overflow-hidden --changedNav_table">
        <div className="row sidebar_row overflow-auto">
          <div className="webNavbar  sticky-top col-lg-12 col-sm-3 col-xl-2 px-0  ">
            {/* <div className="col-lg-12 col-sm-3 col-xl-2 px-sm-2 px-0 "> */}

            <SidebarPay />

            {/* </div> */}
          </div>
          {/* <div className="col-12 col-sm-3 col-xl-2 px-sm-2 px-0 bg-dark d-flex sticky-top">
            <SidebarPay/>
            </div> */}
          <div className="col d-flex flex-column h-sm-auto view_employee">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div
                  style={{
                    margin: "20px 10px",
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <label
                    style={{
                      fontSize: "18px",
                      fontWeight: "800",
                      marginRight: "8px",
                      padding: "20px 20px 10px 10px",
                    }}
                  >
                    Employee Id
                  </label>

                  <Controller
                    control={control}
                    name="employeeId"
                    render={(field) => (
                      <InputField
                        viewEmployeeCss="--updatepayslippage"
                        {...field}
                        placeholder="Employee ID"
                        type="text"
                        disabled={user == "employee"}
                      />
                    )}
                    // rules={{ required: true }}
                  />
                </div>
                <div style={{ margin: "20px 20px", display: "flex",
                    flexWrap: "wrap", }}>
                  <label
                    style={{
                      fontSize: "18px",
                      fontWeight: "800",
                      marginRight: "10px",
                      padding: "20px 20px 10px 0px",
                    }}
                  >
                    Select Month
                  </label>

                  <Controller
                    control={control}
                    name="correntdate"
                    render={({ field,fieldState }) => (
                      <DatePicker 
                        className="--updatepayslippage"
                        {...field}
                        maxDate={new Date()}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        showFullMonthYearPicker
                        yearDropdownItemNumber={100}
                        placeholderText="Select Month/Year"
                        selected={field.value}
                        style={fieldState&&fieldState?.error ? { borderColor: 'red' } : {}}
                        onChange={(date) => field.onChange(date)}
                        minDate={minDate}
                      />
                    )}
                    // rules={{ required: true }}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button
                    className="loginBtn"
                    // style={{
                    //   width: "200px",
                    //   height: "40px",
                    //   marginTop: "30px",
                    //   borderRadius: "6px",
                    //   border: "none",
                    //   backgroundColor: "yellowGreen",
                    // }}
                    type="submit"
                    // onClick={handleLogin}
                  
                  >
                    {isLoading ? (
                      <i class="fa fa-spinner fa-spin"></i>
                    ) : (
                      "Show PaySlip"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
